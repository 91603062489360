/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
//import Index from "views/Index.js";



//import LogDevices from "./views/partials/LogDevices.js";
import LogServer from "./views/partials/LogServer.js";
import LogScheduler from "./views/partials/LogScheduler";
import Homepage from "./views/partials/Homepage"
import Help from "./views/partials/Help"
//import Login from "views/partials/Login/index";
var routes = [

  {
    path: "/logserver",
    name: "log server",
    icon: "fas fa-balance-scale ",
    component: LogServer,
    layout: "/admin",
  },
  {
    path: "/scheduler",
    name: "tabella scheduler",
    icon: "fas fa-balance-scale ",
    component: LogScheduler,
    layout: "/admin",
  },
  /*{
    path: "/devices",
    name: "logDevices",
    icon: "fas fa-balance-scale ",
    component: LogDevices,
    layout: "/admin",
  },*/
  {
    path: "/home",
    name: "homepage",
    icon: "fas fa-balance-scale ",
    component: Homepage,
    layout: "/admin",
  },

  {
    path: "/help",
    name: "api",
    icon: "fas fa-balance-scale ",
    component: Help,
    layout: "/admin",
  },
];
export default routes;
