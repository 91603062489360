/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import SearchBar from './SearchBar'
// reactstrap components
import {

  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  Nav

} from "reactstrap";
import { useSelector } from "react-redux";



const AdminNavbar = ({ setInputSearch, inputSearch }) => {
  const appInfo = useSelector(state => state.app.APPINFO)
  return (
    <>
      <Navbar className="navbar-horizontal navbar-dark bg-default" expand="sm">
        <NavbarBrand href="/">{appInfo.appName}</NavbarBrand>

        <Nav navbar>
          <NavItem>
            <NavLink className="nav-link-light" href="/admin/home" >Home</NavLink>
          </NavItem>
          <NavItem >
            <NavLink className="nav-link-light" href="/admin/logserver" >Log server</NavLink>
          </NavItem>
          <NavItem >
            <NavLink className="nav-link-light" href="/admin/scheduler" >Scheduler</NavLink>
          </NavItem>
          <NavItem >
            <NavLink className="nav-link-light" href="/admin/help" >Api helper</NavLink>
          </NavItem>
          <SearchBar setInputSearch={setInputSearch} inputSearch={inputSearch} />

        </Nav>


      </Navbar>
    </>
  );
};

export default AdminNavbar;
