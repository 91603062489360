
import React, { Fragment, useEffect, useState, useCallback } from "react";
import system from "../../components/system"
import { CSSTransitionGroup } from 'react-transition-group'
// reactstrap components
import { Card, Container, Row, Col, Table } from "reactstrap";

import { faKey } from '@fortawesome/free-solid-svg-icons';
import ApiDetailCard from './ApiDetailCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Help = ({ setIsLoading }) => {
    const [apiList, setApiList] = useState([]) //salvo array scheduler nello stato inizializzando ad array vuoto
    const [selectedApi, setSelectedApi] = useState({ methods: [''] })
    const [isCardOpen, setIsCardOpen] = useState(false)



    useEffect(() => {
        getApiList()

    }, [])

    //ottieni lista schedulatori
    const getApiList = useCallback(() => {
        setIsLoading(true)
        system.help().then(res => {
            console.log("res.data.tata", res.data.data)
            setApiList(res.data.data)
        }).catch(err => {
        }).finally(() => {
            setIsLoading(false)
        })
    }, [])

    const getBadgeBackground = (method) => {
        switch (method) {
            case 'GET':
                return '#033C73';
            case 'POST':
                return '#ced4da';
            case 'UPDATE':
                return '#DD5600';
            case 'DELETE':
                return '#343a40';
            default:
                return '#343a40'
        }
    }


    return (
        <Fragment>
            <CSSTransitionGroup
                component="div"
                transitionName="HeaderAnimation"
                transitionAppear={true}
                transitionAppearTimeout={1500}
                transitionEnter={false}
                transitionLeave={false}>
                <Container className="" fluid>
                    <Row className="justify-content-center">

                        {/* Card con lista API */}
                        <Col className="mb-5 mb-xl-0" sm="6" md="4" lg="4" xl="3">
                            <Card className="shadow mt-3" >
                                <Table responsive striped={true}>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th className="text-left" >EndPoint</th>
                                            <th className="text-left">Methods</th>
                                            <th className="text-left"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {apiList.map((el, index) => {
                                            return (
                                                <tr key={index} onClick={() => {
                                                    setSelectedApi(el)
                                                    setIsCardOpen(true)
                                                }} style={{ backgroundColor: el === selectedApi ? '#cce5ff' : '' }}>
                                                    <th scope="row">{index}</th>
                                                    <td className="text-left"><code>{el.path}</code></td>
                                                    <td className="text-left"><span>{el.methods.map((met, i) => {
                                                        return <div key={'met' + i} className={'ml-auto mr-1 badge badge-secondary'} style={{ float: "right", color: "white", backgroundColor: getBadgeBackground(met) }}>{met}</div>
                                                    })}</span>
                                                    </td>
                                                    <td className="text-left">  <FontAwesomeIcon className="ml-2" icon={faKey} hidden={!el.descriptor[0].isSecure} /> </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </Card>
                        </Col>
                        <Col className="mb-5 mb-xl-0" sm="6" md="5" lg="5" xl="4">
                            {/* Card con dettagli API selezionata */}
                            {selectedApi.descriptor && isCardOpen ? <ApiDetailCard selectedApi={selectedApi} setIsCardOpen={setIsCardOpen} /> : ''}
                        </Col>
                    </Row>
                </Container>
            </CSSTransitionGroup>
        </Fragment>
    );


}



export default Help;
