import React, { Fragment, useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import { useDispatch } from "react-redux";
import * as actions from '../../store/actions';


import system from '../../components/system'
import LoadingOverlay from 'react-loading-overlay';
import { CSSTransitionGroup } from 'react-transition-group'


// reactstrap components
import { Card, CardHeader, Container, Row, Col, Nav, DropdownToggle, DropdownMenu, DropdownItem, Button, UncontrolledDropdown, Table } from "reactstrap";


// core components
const LogServer = ({ setIsLoading, inputSearch, setInputSearch }) => {
  const dispatch = useDispatch();
  const [items, setItems] = useState([{ date: "", time: "", logType: "", cardDetailedText: "" }])  //elementi dettagli logs
  const [selectedLogLevel, setSelectedLogLevel] = useState('ERROR') //elemento selezionato della combo di default
  const [selectedLogType, setSelectedLogType] = useState('system') //elemento selezionato della combo di default
  const [ERROR, setERROR] = useState(0)
  //const [isLoading,setIsLoading]=useState(false)// valori dei badge nella combo
  const [INFO, setINFO] = useState(0)   //
  const [DEBUG, setDEBUG] = useState(0) //
  const [TRACE, setTRACE] = useState(0) //
  const [ALL, setALL] = useState(0)     // valori dei badge nella combo
  const [activePage, setActivePage] = useState(1) //selezione pagina pagination
  const logCategories = ['ERROR', 'INFO', 'DEBUG', 'TRACE', 'ALL'] //tipi di log
  const itemPerPage = 50 //elementi per pagina pagination
  const [display, setDisplay] = useState(Array(itemPerPage).fill(false)) //array di flag per mostra/nascondi testo troncato per ogni riga della pagina
  const [max, setMax] = useState(192)  //numero di caratteri massimi per riga prima di troncare e  mostrare "tasto espandi"
  //parametri per pagination
  var indexOfLastTodo = activePage * itemPerPage
  var indexOfFirstTodo = indexOfLastTodo - itemPerPage
  const [renderedEvents, setRenderedEvents] = useState(items.slice(indexOfFirstTodo, indexOfLastTodo))
  //array dove salvare i log tornati dalla procedura
  var arr = []
  const [templogtypoelevel, setTemplogtypoelevel] = useState("")


  useEffect(() => {
    //filtra log in base a casella di ricerca
    filterLogs(items)

    if (templogtypoelevel !== selectedLogType + selectedLogLevel) {
      getLogData(selectedLogType, selectedLogLevel) //ottengo dati tabella log per tipo e livello 

      logCategories.forEach((el) => {
        //inizializzo badge del dropdown che indicano numero di log per tipo
        system.getLogs(selectedLogType, el).then((res) => {
          switch (el) {
            case 'ERROR':
              return (
                setERROR(res.data.length)
              )
            case 'INFO':
              return (
                setINFO(res.data.length)
              )
            case 'DEBUG':
              return (
                setDEBUG(res.data.length)
              )
            case 'TRACE':
              return (
                setTRACE(res.data.length)
              )
            case 'ALL':
              return (
                setALL(res.data.length)
              )
            default:
          }
        })

      })
    }
  }, [selectedLogLevel, selectedLogType, inputSearch]) //rerender pagina solo se cambiano questi stati


  //filtra log in base a casella di ricerca
  const filterLogs = (logs) => {
    //filtra righe per data, ora,testo log
    var filteredEvents = logs
    if (inputSearch) {
      filteredEvents = (logs.slice(indexOfFirstTodo, indexOfLastTodo).filter(el => {
        return el.cardDetailedText.toLowerCase().includes(inputSearch.toLowerCase()) || el.time.includes(inputSearch) || el.date.includes(inputSearch)
      }))
    }

    //mostra elementi filtrati
    setRenderedEvents(filteredEvents)
  }

  //troncamento testo e icona readmore
  function ReadMoreReact(text, index) {

    //tronco testo in 2 in base a numero caratteri max
    var primaryText = text.substr(0, max)
    var secondaryText = text.substr(max, text.length)
    if (!secondaryText) { //se lunghezza testo < max 
      return <span>{text}</span>
    } else if (secondaryText && !display[index]) { //se lunghezza testo > max e display=false
      return <span>{primaryText}...</span>
    } else if (secondaryText && display[index]) { //se lunghezza testo > max e display=true
      return <span>{text}</span>
    }


  }
  //ottieni lista log per type e level
  const getLogData = (type, level) => {
    setIsLoading(true)
    setActivePage(1)
    setRenderedEvents([])
    setTemplogtypoelevel(type + level)
    arr = [] //reimposto visualizzazione prima pagina
    system.getLogs(type, level).then((res) => {


      res.data.forEach((el) => {
        //formatto data e ora per renderle più leggibili
        var date = el.date.split('T')[0].split('-')[2] + '/' + el.date.split('T')[0].split('-')[1] + '/' + el.date.split('T')[0].split('-')[0]
        var time = el.date.split('T')[1].split('.')[0]
        //creo array di elementi per tabella logs

        arr.unshift({ date: date, time: time, logType: el.level, cardDetailedText: el.message, stack: el.stack })
      })
      //salvo stato items
      dispatch(actions.setLogs(res.data))//salvo logs in stato store
    }).finally(() => {

      //aggiorno items
      setItems(arr)
      //aggiorno caricamento
      setIsLoading(false)
      //filtro log con casella ricerca
      filterLogs(arr)

    })
  }
  //refresh pagina
  const refreshPage = () => {
    getLogData(selectedLogType, selectedLogLevel)
  }

  //aggiorna testo troncato visibile o meno
  const updateDisplayRow = (index) => {
    let arr = [...display]
    arr[index] = !arr[index]
    setDisplay(arr)
  }
  //cambio pagina tabella
  const handlePageChange = (pageNumber) => {
    setDisplay(Array(itemPerPage).fill(false))
    setActivePage(pageNumber)
    indexOfLastTodo = pageNumber * itemPerPage
    indexOfFirstTodo = indexOfLastTodo - itemPerPage
    setRenderedEvents(items.slice(indexOfFirstTodo, indexOfLastTodo))
  }

  //seleziona colore primario log
  const getLogColor = (logType) => {
    switch (logType) {
      case 'ERROR':
        return 'badge-danger';
      case 'INFO':
        return 'badge-primary';
      case 'TRACE':
        return 'badge-secondary';
      case 'DEBUG':
        return 'badge-warning';
      default:
        return 'badge-dark'
    }
  }
  //seleziona colore primario log
  const getBadgeBackground = (logType) => {
    switch (logType) {
      case 'ERROR':
        return '#C71C22';
      case 'INFO':
        return '#033C73';
      case 'TRACE':
        return '#ced4da';
      case 'DEBUG':
        return '#DD5600';
      default:
        return '#343a40'
    }
  }

  return (
    <LoadingOverlay active={false} spinner text='Loading your content...'>
      <Fragment>
        <CSSTransitionGroup
          component="div"
          transitionName="HeaderAnimation"
          transitionAppear={true}
          transitionAppearTimeout={1500}
          transitionEnter={false}
          transitionLeave={false}>
          {/* Page content */}
          <Container className="" fluid>
            <Row className="">
              <Col className="mb-5 mb-xl-0 p-0" xl="12">
                {/*Header con dropdown*/}
                <Card className="shadow">
                  <CardHeader className="border-0 pb-0">
                    <Row className="align-items-center mt-3 mb-3">
                      <Col md='8'><h2 className="p-0" >Log Server </h2></Col>
                      <Col md='1' className="text-right"></Col>
                      <Col md='3' className="text-right">
                        <UncontrolledDropdown className="d-inline-block">
                          <DropdownToggle type="button" color="default" caret>
                            <span className="btn-icon-wrapper pr-2 opacity-7"></span>{selectedLogLevel}
                          </DropdownToggle>
                          <DropdownMenu right>
                            <DropdownItem active={selectedLogLevel === "ERROR"} onClick={() => setSelectedLogLevel('ERROR')}>
                              <i className="nav-link-icon lnr-inbox"> </i>
                              <span>Error</span>
                              <div className="pull-right badge badge-danger" style={{ float: "right", color: "white", backgroundColor: getBadgeBackground('ERROR') }}>{ERROR}</div>
                            </DropdownItem>
                            <DropdownItem active={selectedLogLevel === "INFO"} onClick={() => setSelectedLogLevel('INFO')}>
                              <i className="nav-link-icon lnr-book"> </i>
                              <span>Info</span>
                              <div className="pull-right badge  badge-primary" style={{ float: "right", color: "white", backgroundColor: getBadgeBackground('INFO') }}>{INFO}</div>
                            </DropdownItem>
                            <DropdownItem active={selectedLogLevel === "DEBUG"} onClick={() => setSelectedLogLevel('DEBUG')}>
                              <i className="nav-link-icon lnr-picture"> </i>
                              <span>Debug</span>
                              <div className="pull-right badge badge-warning" style={{ float: "right", color: "white", backgroundColor: getBadgeBackground('DEBUG') }}>{DEBUG}</div>
                            </DropdownItem>
                            <DropdownItem active={selectedLogLevel === "TRACE"} onClick={() => setSelectedLogLevel('TRACE')}>
                              <i className="nav-link-icon lnr-file-empty"> </i>
                              <span>Trace</span>
                              <div className="pull-right badge  badge-secondary" style={{ float: "right", color: "black", backgroundColor: getBadgeBackground('TRACE') }}>{TRACE}</div>
                            </DropdownItem>
                            <DropdownItem active={selectedLogLevel === "ALL"} onClick={() => setSelectedLogLevel('ALL')}>
                              <i className="nav-link-icon lnr-file-empty"> </i>
                              <span>All</span>
                              <div className="pull-right badge  badge-dark" style={{ float: "right", color: "white", backgroundColor: getBadgeBackground('ALL') }}>{ALL}</div>
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                        <UncontrolledDropdown className="d-inline-block ">
                          <DropdownToggle type="button" color="default" caret>
                            <span className="btn-icon-wrapper pr-2 opacity-7"></span>{selectedLogType}
                          </DropdownToggle>
                          <DropdownMenu right>
                            <DropdownItem active={selectedLogType === "error"} onClick={() => setSelectedLogType('error')}>
                              <i className="nav-link-icon lnr-inbox"> </i>
                              <span>Error</span>

                            </DropdownItem>
                            <DropdownItem active={selectedLogType === "system"} onClick={() => setSelectedLogType('system')}>
                              <i className="nav-link-icon lnr-book"> </i>
                              <span>System</span>

                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>

                        <Button type="button" onClick={() => { refreshPage() }} color="primary">Aggiorna
                          <i className="fas fa-sync-alt ml-2"></i>
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  {/* ogni 10 elementi creo un nuovo indice pagina */}

                  <Nav hidden={!items[0]} className="justify-content-center mb-3">
                    <Pagination
                      hideNavigation
                      activePage={activePage}
                      itemsCountPerPage={itemPerPage}
                      totalItemsCount={items.length}
                      pageRangeDisplayed={5}
                      onChange={handlePageChange}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </Nav>
                  <Nav hidden={items[0]}> <span >Non ci sono log {selectedLogLevel}!</span></Nav>
                  <div hidden={!items[0]}>
                    <Table responsive striped className="mb-0">
                      <thead>
                        <tr>
                          <th>Data</th>
                          <th>Orario</th>
                          <th>Tipo</th>
                          <th>Messaggio</th>
                          <th>Stack</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {renderedEvents.map((el, index) => {
                          if (el.cardDetailedText) {
                            return (
                              <tr key={index} >
                                <td style={{ width: "80px" }}>{el.date}</td>
                                <td style={{ width: "80px" }}>{el.time}</td>
                                <td style={{ width: "80px" }}> <div className={'ml-auto badge ' + getLogColor(el.logType)} style={{ color: el.logType === 'TRACE' ? "black" : "white", backgroundColor: getBadgeBackground(el.logType) }}>{el.logType}</div></td>
                                <td className="text-left" style={{ width: "400px" }}>
                                  {el.cardDetailedText}{/*readLessText={<b style={{cursor:"pointer"}}>Clicca per chiudere</b>}*/}
                                </td>
                                <td className="text-left" ><code hidden={el.stack.length < 1}>{ReadMoreReact(JSON.stringify(el.stack), index)}</code></td>
                                <td >
                                  <i hidden={(JSON.stringify(el.stack)).length <= max} style={{ cursor: "pointer" }}
                                    onClick={() => { updateDisplayRow(index) }} className={!display[index] ? "fas fa-angle-down fa-lg" : "fas fa-angle-up fa-lg"}>
                                  </i>
                                </td>
                              </tr>
                            )
                          }
                        })}
                      </tbody>
                    </Table>
                  </div>
                </Card>
              </Col>
            </Row>
          </Container>
        </CSSTransitionGroup>
      </Fragment>
    </LoadingOverlay>
  );

}






export default LogServer;
