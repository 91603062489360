import API, { APInoToken, manageError } from './api'
//import swal from 'sweetalert';



/**
 * ottiene log server
 */
async function getLogs(type, level) {
    try {
        let data = await API.get('/sys/logs', { params: { type: type, level: level } })
        return data.data;
    } catch (err) {

        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }
}



/**
 * ottiene lista schedulatori
 */
async function getLogScheduler() {
    try {
        let data = await API.get('/sys/schedulerslogs')
        return data;
    } catch (err) {

        manageError(err.response)

        throw err.response
    }
}


/**
 * ottiene info di utente
 */
/*async function getMenu(IdMenuCategory) {
    try {
        let data = await API.get('/sys/menu', { params: { IdMenuCategory: IdMenuCategory } })
        return data.data.menu;
    } catch (err) {
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }
}*/
/**
 * ottiene info di utente
 */
async function getLastMessage() {
    try {
        let data = await API.get('/sys/lastmessage')
        return data.data;
    } catch (err) {
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }
}
/**
 * fa il login
 
async function login(user,pass){   
    try{  
        let data = await APInoToken.post("/auth/login", {
            username: user,
            password: pass
        })

        window.localStorage.setItem('hoppla_token',data.data.token)
        //window.location.replace('/dashboard')
        //history.push("/admin");
        //this.handleClick()
        refresh()
    }catch(err){
        throw err.response
    }
           
} */

/**
 * ottiene info chiamate API
 */
async function help() {
    try {
        let apiInfo = await APInoToken.get('/sys/help')
        return apiInfo;
    } catch (err) {
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }
}
/**
 * ottiene info chiamate API
 */
async function info() {
    try {
        let apiInfo = await APInoToken.get('/sys/info')
        return apiInfo;
    } catch (err) {
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }
}

export default {
    //getMenu,
    getLogs,
    getLogScheduler,
    getLastMessage,
    help,
    info
}


