import * as ActionTypes from '../types';

const initialState = {
    APPINFO: { appName: "", appVersion: "", appMessage: "", appDescription: "", appAuthor: "", appCustomer: "" },
    SERVERLOGS: [],





};

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case ActionTypes.APP_INFO:
            return state.APPINFO;

        case ActionTypes.SET_APP_INFO:
            return {
                ...state,
                APPINFO: action.payload
            };
        case ActionTypes.SET_SERVER_LOGS:
            return {
                ...state,
                SERVERLOGS: action.payload
            };
        case ActionTypes.GET_SERVER_LOGS: {
            console.log("get log red", state)
            return state.SERVERLOGS
        }
        default:
            return { ...state };
    }
};

export default reducer;