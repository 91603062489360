import { createStore, combineReducers,applyMiddleware } from 'redux';
import authReducer from './reducers/auth';
import appinfo from "./reducers/app"
import getPese from "./reducers/pese"
import reduxThunk from "redux-thunk";
const rootReducer = combineReducers({
    auth: authReducer,
    app: appinfo,
    pese: getPese
});

const store = createStore(rootReducer,{}, applyMiddleware(reduxThunk));
export default store;