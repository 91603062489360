/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";

import "./assets/plugins/nucleo/css/nucleo.css";

import "./assets/scss/argon-dashboard-react.scss";
import store from "./store/index";
import AdminLayout from "./layouts/Admin.js";
import NotFoundPage from "./views/NotFound"

//import AuthLayout from "layouts/Auth.js";
//import PrivateRoute from "./components/PrivateRoute.js"

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
        {/*<PrivateRoute path="/admin" component={(props) => <AdminLayout {...props} />} isAuth={store.gettate()} />*/}
        {/*<Route path="/auth" render={(props) => <AuthLayout {...props} />} />*/}
        <Route exact path='/'><Redirect to="/admin/home" /></Route>
        <Route path="/404" component={NotFoundPage} />
        <Route component={NotFoundPage} />
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
