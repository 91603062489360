/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";

import { useLocation, Route, Switch, useHistory } from "react-router-dom";
// reactstrap components
//import { Container } from "reactstrap";
// core components
import AdminNavbar from "../components/Navbars/AdminNavbar.js";
//import AdminFooter from "components/Footers/AdminFooter.js";
//import Sidebar from "components/Sidebar/Sidebar.js";
import LoadingOverlay from 'react-loading-overlay';
import routes from "../routes";

const Admin = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false)
  const [inputSearch, setInputSearch] = useState("")
  React.useEffect(() => {

    //verifico che url sia valido sennò push a 404 page
    var isFound = routes.find(el => {
      //confronto gli url in routes e il pathname passato dal router(togliendo i primi 6 caratteri relativi a 'admin/')
      //console.log(el.path===props.location.pathname.substr(6),el.path,props.location.pathname.substr(6))
      return el.path === props.location.pathname.substr(6).toLowerCase()
    })
    //se non trovo corrispondenze => 404 page
    if (!isFound) history.push("/404");

    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route path={prop.layout + prop.path} key={key}
            render={(props) => (<prop.component {...props} inputSearch={inputSearch} setInputSearch={setInputSearch} setIsLoading={setIsLoading} />)}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  return (
    <>
      {/* <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/admin/index",
          imgSrc: require("../assets/img/brand/logo.png").default,
          imgAlt: "...",
        }}
      />*/}
      <LoadingOverlay className="w-100" active={isLoading} spinner text='Loading your content...'>
        <div className="main-content" ref={mainContent}>
          <AdminNavbar

            {...props} setInputSearch={setInputSearch} inputSearch={inputSearch} brandText={getBrandText(props.location.pathname)}

          />
          <Switch>
            {getRoutes(routes)}

          </Switch>

        </div>
      </LoadingOverlay>
    </>
  );
};

export default Admin;
