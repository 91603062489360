export const GET_SERVER_LOGS = 'get_log';
export const SET_SERVER_LOGS = 'set_log';

export const APP_INFO = "APP_INFO" 
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';

export const LOAD_USER = 'LOAD_USER';

export const SET_APP_INFO = "SET_APP_INFO"
export const AUTH_LOAD = "AUTH_LOAD"
export const TOKEN_LOAD = "TOKEN_LOAD"

export const GET_CARICHI = "GET_CARICHI"
export const SET_CARICHI = "SET_CARICHI"