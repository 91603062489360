
import React from "react";

// reactstrap components
import { Card, CardHeader, CardBody, Table, Badge, Button } from "reactstrap";



const ApiDetailCard = ({ selectedApi, setIsCardOpen }) => {
    const getBadgeBackground = (method) => {
        switch (method) {
            case 'GET':
                return '#033C73';
            case 'POST':
                return '#ced4da';
            case 'UPDATE':
                return '#DD5600';
            case 'DELETE':
                return '#343a40';
            default:
                return '#343a40'
        }
    }
    return (
        <Card className="shadow mt-3" >
            <CardHeader style={{ textTransform: 'lowercase' }}>
                <code>{selectedApi.path ? selectedApi.path : null}</code>
                <div className="ml-auto">
                    <Button close onClick={() => setIsCardOpen(false)} />
                </div>
            </CardHeader>
            <CardBody>
                {selectedApi.methods ? selectedApi.methods.map((el, index) => {
                    return (
                        <div key={index + index}>
                            <span>

                                <span className="h4"><Badge color="secondary" style={{ color: "white", backgroundColor: getBadgeBackground(el) }}>{el}</Badge></span> {" "}
                                <small className="m-auto">
                                    token:{" "}
                                    <Badge color="success" hidden={selectedApi.descriptor ? !selectedApi.descriptor[index].isSecure : true}>true</Badge>
                                    <Badge color="danger" hidden={selectedApi.descriptor ? selectedApi.descriptor[index].isSecure : true}>false</Badge>
                                </small>

                            </span>
                            <p>{selectedApi.descriptor ? selectedApi.descriptor[index].descr : ''}</p>
                            <Table hidden={selectedApi.descriptor[index].params.length < 1} bordered={false} striped>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th className="text-left" >Key</th>
                                        <th className="text-left">Type</th>
                                        <th className="text-left">Mandatory</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedApi.descriptor[index].params ? selectedApi.descriptor[index].params.map((des, i) => {
                                        return (
                                            <tr key={i} key={'des' + i} >
                                                <th scope="row">{i}</th>
                                                <td className="text-left"><span>{Object.keys(des)[0]}</span></td>
                                                <td className="text-left"><span>{des[Object.keys(des)[0]]}</span></td>
                                                <td className="text-left">  <span> {des[Object.keys(des)[1]] ? 'true' : 'false'}</span> </td>
                                            </tr>
                                        )
                                    }) : null}

                                </tbody>
                            </Table>

                            <hr hidden={index === selectedApi.descriptor.length - 1} />
                        </div>
                    )
                }) : null}
            </CardBody>
        </Card>
    )
}
export default ApiDetailCard