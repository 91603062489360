




import { GET_SERVER_LOGS, SET_SERVER_LOGS, SET_APP_INFO, APP_INFO } from './types';


//aggiorna stato che contiene i server logs 
export const setAppInfo = (data) => {
    return {
        type: SET_APP_INFO,
        payload: data
    }
};

//ottiene stato che contiene i server logs 
export const getAppInfo = () => {
    return {
        type: APP_INFO,

    }
};
//aggiorna stato che contiene i server logs 
export const setLogs = (data) => {
    return {
        type: SET_SERVER_LOGS,
        payload: data
    }
};
//ottiene stato che contiene i server logs 
export const getLogs = () => {
    return {
        type: GET_SERVER_LOGS,

    }
};











/*
export const setCarichi = (carichi) => {
    return {
        type: SET_CARICHI,
        payload: carichi
    }
}

export const getCarichi = () => {
    return {
        type: GET_CARICHI,

    }
}






export const getPese = () => {
    return {
        type: GET_PESE,

    }
}


export const getAppInfo = () => {
    return {
        type: APP_INFO,

    };
};


export const setAppInfo = (info) => {
    return {
        type: SET_APP_INFO,
        payload: info,
    };
};

export const setUserInfo = (userInfo) => {
    return {
        type: LOGIN_USER,
        payload: userInfo

    }
}

export const setAuthLoading = (stato) => {
    return {
        type: AUTH_LOAD,
        payload: stato
    }
}

export const setToken = (token) => {
    return {
        type: TOKEN_LOAD,
        payload: token
    }
}
*/