/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { connect } from "react-redux";
import * as actions from '../../store/actions';
import system from '../../components/system'
// reactstrap components
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Button
} from "reactstrap";
import wsHelper from "../../components/wsHelper";
// core components


class Homepage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      logs: [],
      lastMessage: '',
      lastData: '',
      appInfo: { appName: '', app: '' }
    }
  }
  componentDidMount() {

    this.getWs1()
    //ottengo lista di tutti i log
    system.getLogs('system', 'INFO').then((res) => {
      console.log("res.data", res.data)
      if (res.data) {
        this.setState({
          lastMessage: res.data[res.data.length - 1].message,
          lastData: res.data[res.data.length - 1].date.split('T')[0].split('-')[2] + '/' + res.data[res.data.length - 1].date.split('T')[0].split('-')[1] + '/' +
            res.data[res.data.length - 1].date.split('T')[0].split('-')[0] + ' - ' + res.data[res.data.length - 1].date.split('T')[1].split('.')[0],

        })
      }

    })
    this.getAppInfo()

    //ottengo ultimo messaggio gac
    /*system.getLastMessage().then(res => {

      if (res.data) {
        this.setState({
          lastMessage: res.data
        })
      }
    })*/
  }


  //ottieni lista schedulatori
  getAppInfo = () => {
    // setIsLoading(true)

    system.info().then(res => {

      this.props.setAppInfo(res.data.data)
      document.title = res.data.data.appName
      console.log("ap info", res.data.data)
    }).finally(() => {
      //setIsLoading(false)

    })
  }

  getWs1 = () => {
    wsHelper.getWs1().then(res => {
      console.log("res ws1", res.data)
    })
  }
  render() {
    return (
      <>
        <div className="header pb-8 d-flex align-items-center" style={{ minHeight: "600px", backgroundSize: "auto", backgroundPosition: "center top" }}>
          {/* Header container */}
          <Container className="d-flex align-items-center" fluid>
            <Col md='12'>
              <Row >
                <Col xl='12' lg="12" md="12">
                  <h1 className="display-2 text-center text-uppercase">{this.props.appInfo.serviceWinName} </h1>
                  <p className="text-center mt-0 mb-5">{this.props.appInfo.appDescription} <i>V. {this.props.appInfo.appVersion}</i></p>
                </Col>
                <Col className="m-auto" xl="4">
                  <Card className="card-profile shadow mt-0">
                    <CardBody className="pt-0 pt-md-4">
                      <div className="text-center">
                        <div className="h5 mt-4"><i className="ni business_briefcase-24 mr-2" />{this.props.appInfo.appMessage}</div>
                        <div><i className="ni education_hat mr-2" />{this.state.lastMessage}</div>

                      </div>
                      <Row>
                        <div className="col">
                          <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                            <div>
                              <span className="heading">{this.props.appInfo.appPort}</span>
                              <span className="description">Porta</span>
                            </div>
                            <div>
                              <span className="heading">{this.props.appInfo.appApiBasePath}</span>
                              <span className="description">Indirizzo api</span>
                            </div>
                            <div>
                              <span className="heading">{this.state.lastData}</span>
                              <span className="description">Ultimo log </span>
                            </div>
                          </div>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Container>
        </div>

      </>
    );
  }
};
const mapStateToProps = function (state) {
  return {
    logs: state.app.SERVERLOGS,
    errorlogs: state.app.SERVERERRORLOGS,
    appInfo: state.app.APPINFO,
  }
}


const mapDispatchToProps = dispatch => {
  return {
    setLogs: (level, data) => dispatch(actions.setLogs(level, data)),
    setAppInfo: (data) => dispatch(actions.setAppInfo(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
