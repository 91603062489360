import React, {Fragment} from 'react';


import {Input,InputGroupAddon,InputGroup,InputGroupText} from "reactstrap";
const SearchBar =({setInputSearch,inputSearch})=>{
    
    return (
        <Fragment>
            <div >
                <div className="input-holder">
                    <InputGroup>
                        <Input type="text" className="search-input" style={{borderColor:"white"}} value={inputSearch} onChange={(e)=>{ setInputSearch(e.target.value)}}/>
                        <InputGroupAddon addonType="append">
                        <InputGroupText><i className="fas fa-search"></i></InputGroupText>  
                        </InputGroupAddon>
                    </InputGroup>
                </div>
             </div>
        </Fragment>
    )
}

export default SearchBar;