import API, { APInoToken, manageError } from './api'
//import swal from 'sweetalert';



/**
 * ottiene log server
 */
async function getWs1(type, level) {
    try {
        let data = await APInoToken.get('/jde/wsin1')
        return data.data;
    } catch (err) {

        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }
}




export default {
    //getMenu,
    getWs1,

}


