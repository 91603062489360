import * as ActionTypes from '../types';

const initialState = {
    isLoggedIn: localStorage.getItem('tokenD4i') ? true : false,
    token: "",
    currentUser: {
        email: "MAIL",
        picture: null
    },
    user: {},
    isAuthLoading: false

};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.LOGIN_USER:



            return {
                ...state,
                isLoggedIn: true,
                user: action.payload

            };
        case ActionTypes.LOGOUT_USER:
            localStorage.removeItem('tokenD4i');
            return {
                ...state,
                isLoggedIn: false,
                token: null,
                currentUser: {
                    username: 'mail@example.com',
                    picture: null
                }
            };
        case ActionTypes.LOAD_USER:
            return {
                ...state,
                currentUser: action.currentUser
            };
        case ActionTypes.AUTH_LOAD:
            return {
                ...state,
                isAuthLoading: action.payload
            };
        case ActionTypes.TOKEN_LOAD:
            return {
                ...state,
                token: action.payload
            };
        default:
            return { ...state };
    }
};

export default reducer;
