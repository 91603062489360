import * as ActionTypes from '../types';

const initialState = {
    PESE: {},
    listaCarichiScarichi: {}
};

const reducer = (state = initialState, action) => {
    switch (action.type) {


        case ActionTypes.GET_CARICHI:
            return state.listaCarichiScarichi
        case ActionTypes.SET_CARICHI:
            return {
                ...state,
                listaCarichiScarichi: action.payload
            }
        default:
            return { ...state };
    }
};

export default reducer;