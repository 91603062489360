/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";


import system from '../../components/system'
// reactstrap components
import { Card, CardHeader, CardFooter, Container, Alert, Row, Col, Table, Button } from "reactstrap";

const LogScheduler = ({ setIsLoading }) => {

  const [schedulers, setSchedulers] = useState([])


  useEffect(() => {
    getLogScheduler()
  }, [])

  //ottieni lista schedulatori
  const getLogScheduler = () => {
    setIsLoading(true)
    var retObj = []
    system.getLogScheduler().then(res => {
      console.log(res)
      if (res.data.length > 0) {
        res.data.forEach(el => {
          retObj.push(el)
        })
      } /*else {
        retObj.push({ key: '', lastExcecutionTime: '', lastMessage: '' })
      }*/
    }).catch(err => {
      console.log("err", err)
    }).finally(() => {

      setSchedulers(retObj)
      setIsLoading(false)

    })
  }


  return (
    <>
      {/* Page content */}
      <Container className="mt-3" fluid>
        {/* Table */}
        <Row className="">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0 pb-0">
                <Row className="align-items-center mt-3 mb-3">
                  <Col md='10'> <h2 className="col-11" >Log Scheduler </h2></Col>

                  <Col md='2' className="text-right"><Button className="pull-right" color="primary" type="button" onClick={() => { getLogScheduler() }}>Aggiorna<i className="fas fa-sync-alt ml-2"></i></Button>{' '}</Col>
                </Row>
              </CardHeader>
              {schedulers.length==0 && <Alert color='danger' className="m-3">
                <h2 className="" >Nessuno Scheduler Configurato </h2>
              </Alert>
              }
              {schedulers.length>0 && <Table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th className="text-left" >Task</th>
                    <th className="text-left">Ultima esecuzione</th>
                    <th className="text-left">Messaggio</th>
                  </tr>
                </thead>
                <tbody>
                  {schedulers.length>0 && schedulers.map((el, index) => {
                    return (
                      <tr key={index}>
                        <th scope="row">{index}</th>
                        <td className="text-left">{el.key}</td>
                        <td className="text-left"><code>{el.lastExcecutionTime}</code></td>
                        <td className="text-left">{el.lastMessage}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
              }
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );


}



export default LogScheduler;
